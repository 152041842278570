import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const leclipse = () => (
  <Layout>
    <SEO title="Bananes Citronnées - L'éclipse" />
    <h3 className='underline-title'>L'éclipse</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Comme si tout le continent flambait<br />
        Au moment où la saison sèche jaunissait la brousse<br />
        Les gousses éclatantes signalaient la rigueur torride<br />
        Les denses fumées montaient avec asphyxie rageuse<br />
        Sous leur teinte de deuil plongeait le monde voisin<br />
        L'éclipse avait enfermé l'astre du jour<br />
        Totale éclipse hors cycle<br />
        Les poules effrayées se bousculaient<br />
        Leur vacarme emplit la basse-cour<br />
        La nuit douteuse désoriente les us<br />
        L'étrenne de bonnes âmes dérive<br />
        Un cop chante au hasard à l'appel du jour détenu<br />
        Les poules incertaines espèrent et doutent<br />
        Les ténèbres s'amplifient<br />
        Quelques flambeaux de fortune se consument<br />
        À quand l'astre du jour?<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/bananes-citronnees-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default leclipse
